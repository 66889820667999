import React from 'react';
import Footer from './Footer';
import Header from './Header';
import { Helmet } from 'react-helmet'
import favicon from '../../images/iconos-22.png';

const Layout = ({ children }) => {
  return (
    <>
      <Helmet
        title={"Fundación Proceder"}
        meta={[
          {
            name: "description",
            content: `Promovemos el respeto y las efectividad de los derechos humanos y la sostenibilidad ambiental`,
          },
          {
            name: "keywords",
            content: "fundación, ong, proceder, ngo",
          },
        ]}
        link={[
          { rel: "icon", type: "image/png", sizes: "16x16", href: `${favicon}` },
          { rel: "icon", type: "image/png", sizes: "32x32", href: `${favicon}` },
          { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
        ]}
      />
      <Header />
      <main className="text-gray-900 font-sans">{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
