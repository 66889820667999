export default [
  {
    title: 'BOGOTA D.C.-SECRETARIA DE INTEGRACION SOCIAL',
    content:
      'AUNAR RECURSOS FISICOS, ADMINISTRATIVOS Y ECONOMICOS CON EL FIN DE IMPLEMENTAR UNA ESTRATEGIA COLECTIVA QUE PROMUEVA CAMBIOS EN LOS JOVENES Y SUS ENTORNOS. EN LAS ZONAS DE INFLUENCIA DE LA CASA DE LA JUVENTUD DE LA LOCALIDAD DE CIUDAD BOLIVAR EN BOGOTA D.C.',
    customerName: 'BOGOTA D.C.',
    customerTitle: 'SECRETARIA DE INTEGRACION SOCIAL',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'MUNICIPIO DE SABANALARGA - ATLÁNTICO',
    content:
      'AUNAR ESFUERZOS PARA EL FORTALECIMIENTO DE PROGRAMAS DE INTERÉS PÚBLICO "VA JUGANDO" COMO MECANISMO DE MEJORAMIENTO DE LA CONVIVENCIA EN EL MUNICIPIO DE SABANALARGA - ATLÁNTICO',
    customerName: 'SABANALARGA',
    customerTitle: 'MUNICIPIO DE SABANALARGA - ATLÁNTICO',
    customerImage: 'https://placeimg.com/150/150/people'
  },
  {
    title: 'MUNICIPIO DE GALAPA - ATLÁNTICO',
    content:
      'AUNAR ESFUERZOS ENTRE LA ALCALDÍA Y ENTIDAD SIN ANIMO DE LUCRO EN CUMPLIMIENTO DEL CONVENIO INTERADMINISTRATIVO DE COOPERACIÓN 0135.2.016.000181 SUSCRITO ENTRE EL DEPARTAMENTO DEL ATLÁNTICO Y EL MUNICIPIO DE GALAPA, PARA EL FORTALECIMIENTO DE LA GESTIÓN DE LA SEGURIDAD, CONVIVENCIA CIUDADANA Y CULTURA DE LA LEGALIDAD EN EL MUNICIPIO DE GALAPA A TRAVES DEL PROYECTO DE ATENCIÓN INTEGRAL E INTERVENCIÓN PSICOSOCIAL PARA LA MITIGACIÓN DE LOS FACTORES DE RIESGO INDIVIDUAL, FAMILIAR Y COMUNITARIO QUE FAVORECEN EL CONSUMO DE SUSTANCIAS PSICOACTIVAS Y LA VIOLENCIA INCIDENTAL E INSTRUMENTAL, EN LA POBLACIÓN JUVENIL VULNERABLE, PROGRAMA VA JUGANDO - GALAPA Y LA SOCIALIZACIÓN PLAN NACIONAL DE VIGILANCIA POR CUADRANTES EN EL MUNICIPIO DE GALAPA - ATLÁNTICO.',
    customerName: 'GALAPA',
    customerTitle: 'MUNICIPIO DE GALAPA - ATLÁNTICO',
    customerImage: 'https://placeimg.com/150/150/people'
  }
];
