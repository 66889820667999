import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button';
import header from '../../images/logo.png';
import { Link } from "gatsby"

const Header = () => (
  <header className="sticky top-0 bg-white shadow font-sans">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-2 px-8">
      <div className="flex items-center">
        <div className="w-auto mr-3">
          <Link to="/">
            <img height={"80px"} src={header} alt="brand logo" />
          </Link>
        </div>
      </div>
      <div className="flex items-center">
        <div className="hidden md:block mt-4 sm:mt-0">
          <AnchorLink className="px-4" href="#features">
            Quiénes somos
          </AnchorLink>
          <AnchorLink className="px-4" href="#services">
            Qué hacemos
          </AnchorLink>
          <AnchorLink className="pl-4 pr-8" href="#stats">
            Ayúdanos
          </AnchorLink>
        </div>
        <div className="hidden md:block">
          <Button className="text-sm">Contacto</Button>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
