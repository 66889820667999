import React from 'react';
import footer from '../../images/footer.jpg';
import header from '../../images/logo.png';

const Footer = () => (
  <footer
    className="font-sans mx-auto py-10 px-16 text-gray-800"
    style={{
      backgroundImage: "url(" + footer + ")",
      backgroundSize: 'cover'
    }}
  >
    <div className="flex flex-row justify-between">
    <div>
      <h2 className="text-lg font-semibold">Acerca de nosotros</h2>
      <p className="mt-5">Mabel Hermida Rivera<br/>Directora</p>
    </div>
    <div>
      <h2 className="text-lg font-semibold">Contáctanos</h2>
      <ul className="mt-4 leading-loose">
        <li>
          <a href="emailto:info@fundacionproceder.org">info@fundacionproceder.org</a>
        </li>
        <li>
          Cra 76 N° 80 - 82
        </li>
        <li>
          315 710 1093 - 315 760 3996
        </li>
        <li>
          Barranquilla, Colombia
        </li>
      </ul>
    </div>
      <div className="px-8">
        <img height={"80px"} src={header} alt="brand logo" />
      </div>
    </div>
    <div className="flex pt-10">
      <small>
        ©2020. Fundación Proceder. Todos los derechos reservados
      </small>
    </div>
  </footer>
);

export default Footer;
