import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';

// Images
import logo01 from '../images/iconos-23.png';
import logo02 from '../images/iconos-22.png';
import serv01 from '../images/serv01.png';
import serv02 from '../images/serv02.png';
import serv03 from '../images/serv03.png';
import apoyo01 from '../images/apoyo01.png';
import apoyo02 from '../images/apoyo02.png';
import apoyo03 from '../images/apoyo03.jpg';
import section01 from '../images/section01.jpg';
import section02 from '../images/alcancias.png';
import section03 from '../images/bolsos.png';
import section04 from '../images/otros.png';

// Files

import camaraComercio from '../files/camara.pdf'
import estadosFinancieros from '../files/estados.pdf'
import estatutos from '../files/estatutos.pdf'
import informe from '../files/informe.pdf'
import renta from '../files/renta.pdf'
import rut from '../files/rut.pdf'


export default () => (
  <Layout>
    <section
      className="py-20"
      style={{
        backgroundImage: "url(" + section01 + ")",
        backgroundSize: 'cover'
      }}
    >
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <img className="" height={"34px"} src={logo01} alt="brand icon" />
          <p className="text-xl lg:text-2xl mt-6 font-light text-white">
            Promovemos el respeto y la efectividad de los
          </p>
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none text-white">
            Derechos humanos y la sostenibilidad ambiental
          </h1>
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-20 lg:pt-48">
      <div className="container mx-auto text-center">
        <img className="mx-auto my-6" height={"34px"} src={logo02} alt="brand icon" />
        <h3 className="text-3xl lg:text-4xl font-semibold">Promovemos la dignidad y los derechos humanos</h3>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img className="mx-auto mb-6" height={"50px"} src={serv01} alt="brand icon" />
              <p className="font-semibold text-xl">Estrategias</p>
              <p className="mt-4">
                Las principales estrategias que empleamos en nuestro accionar son la intervención psicosocial, la participación ciudadana, la celebración de acuerdos y la corresponsabilidad social.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img className="mx-auto mb-6" height={"50px"} src={serv02} alt="brand icon" />
              <p className="font-semibold text-xl">Servicios</p>
              <p className="mt-4">
                Prestamos servicios de diseño, asesoría e implementación de proyectos de intervención psicosocial, desarrollo comunitario y seguridad ciudadana, prevención y superación de la violencia intrafamiliar.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <img className="mx-auto mb-6" height={"50px"} src={serv03} alt="brand icon" />
              <p className="font-semibold text-xl">Experiencia</p>
              <p className="mt-4">
                La Fundación Proceder posee una amplia trayectoria en la ejecución de proyectos de desarrollo humano, educación ambiental, atención psicosocial, derechos humanos y salud sexual y reproductiva.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section id="features" className="py-20">
      <div className="container mx-auto text-center">
        <img className="mx-auto my-6" height={"34px"} src={logo02} alt="brand icon" />
        <h3 className="text-3xl lg:text-4xl font-semibold">Emprendimiento autosostenible de nuestros beneficiarios</h3>
      </div>
    </section>
    <SplitSection
      id="services"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Mochilas y bolsos</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Bolsos artesanales, hechos a mano, con material reciclable.
            Tamaño: 30 x 30 cms
            Unidades disponibles: 1 unidad
            Elaborado por:
            Martin Jimenez Consuegra, 27 años
          </p>
        </div>
      }
      secondarySlot={<img className="mx-auto mb-6" height={"50px"} src={section03} alt="brand icon" />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Alcancías personalizadas
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Alcancías personalizadas, hechos a mano, con material reciclable.
          </p>
        </div>
      }
      secondarySlot={<img className="mx-auto mb-6" height={"50px"} src={section02} alt="brand icon" />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Otros productos
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Otros productos, hechos a mano, con material reciclable.
          </p>
        </div>
      }
      secondarySlot={<img className="mx-auto mb-6" height={"50px"} src={section04} alt="brand icon" />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Nuestros resultados</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100K" secondaryText="Personas apoyadas" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+10" secondaryText="Comunidades" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+$10M" secondaryText="Apoyos generados" />
          </div>
        </div>
      </div>
    </section>
    <section id="testimonials" className="py-20 lg:py-20">
      <div className="container mx-auto">
        <LabelText className="mb-8 text-gray-600 text-center">Estos son algunos proyectos</LabelText>
        <div className="flex flex-col md:flex-row md:-mx-3">
          {customerData.map(customer => (
            <div key={customer.customerName} className="flex-1 px-3">
              <CustomerCard customer={customer} />
            </div>
          ))}
        </div>
      </div>
    </section>
    <section id="features" className="py-20">
      <div className="container mx-auto text-center">
        <img className="mx-auto my-6" height={"34px"} src={logo02} alt="brand icon" />
        <h3 className="text-3xl lg:text-4xl font-semibold">Regimen Tributario Especial</h3>
        <div className="flex flex-row sm:flex-row sm:-mx-3 mt-12 items-center justify-center">
          <div className="px-3">
            <Card className="mb-8">
              <a href={estatutos} className="py-2 block">Estatutos de la fundación</a>
              <a href={estadosFinancieros}  className="py-2 block">Estados financieros</a>
              <a href={camaraComercio} className="py-2 block">Cámara de Comercio</a>
              <a href={renta}  className="py-2 block">Declaración de rentas</a>
              <a href={rut}  className="py-2 block">Registro Único Tributario</a>
              <a href={informe}  className="py-2 block">Informe final de resultados</a>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 rounded-lg text-center">
      <h3 className="text-4xl font-semibold">Nos apoyan</h3>
      <div className="flex flex-row justify-around my-20">
        <img className="h-20" src={apoyo01} alt="brand logo" />
        <img className="h-20" src={apoyo02} alt="brand logo" />
        <img className="h-20" src={apoyo03} alt="brand logo" />
      </div>
    </section>
  </Layout>
);
